<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-7">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  Setările sălii
                </h3>
                <div class="form-group">
                  <label>Denumire</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="denumire"
                    placeholder="Sala 1"
                    value="Sala 1"
                    ref="name"
                  />
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Descriere</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="capacitate"
                        placeholder="Descrierea salii...."
                        value="Descrierea salii...."
                        ref="descriere"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Capacitate</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="capacitate"
                        placeholder="10"
                        value="10"
                        ref="places"
                      />
                      <span class="form-text text-muted"
                        >Adăugați capacitatea sălii (număr de persoane)</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Facilitati</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="facilitati"
                        placeholder=""
                        value=""
                        ref="amenities"
                      />
                      <span class="form-text text-muted"
                        >Adăugați facilitatile sălii (proiector, vedere
                        oras)</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Pret de baza</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="default_price"
                        placeholder="Pret de baza"
                        value="100"
                        ref="default_price"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group"></div>
                  </div>
                </div> -->
                <div class="row">
                  <!-- <div class="col-xl-6">
                    <div class="form-group">
                      <label>Pret curent</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="price"
                        placeholder="Pret"
                        value="100"
                        ref="price"
                      />
                    </div>
                  </div> -->
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Disponibila de la </label>
                      <b-form-input
                        id="type-date"
                        type="date"
                        ref="date_start"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Pana la</label>
                      <b-form-input
                        id="type-date"
                        type="date"
                        ref="date_end"
                      ></b-form-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Imagini</label>
                      <v-file-input
                        show-size
                        counter
                        multiple
                        label="File input"
                        ref="img1"
                      ></v-file-input>

                      <!--                      <v-file-input show-size ref="img1" label="File input"></v-file-input>-->
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group ">
                      <label></label>
                      <v-file-input
                        show-size
                        ref="img2"
                        label="File input"
                      ></v-file-input>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-file-input
                        show-size
                        ref="img3"
                        label="File input"
                      ></v-file-input>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <v-file-input
                        show-size
                        ref="img4"
                        label="File input"
                      ></v-file-input>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div>
                  <button
                    v-on:click="submit"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    ref="kt_add_new_room"
                  >
                    Salvează
                  </button>
                  <!--                  <button-->
                  <!--                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"-->
                  <!--                    data-wizard-type="action-next"-->
                  <!--                  >-->
                  <!--                    Următorul pas-->
                  <!--                  </button>-->
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import { mapGetters } from "vuex";
import { CREATE_ROOM } from "@/core/services/store/room.module";
import Swal from "sweetalert2";

export default {
  name: "Wizard-1",
  data() {
    return {
      img1: ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Admin" },
      { title: "Adaugă o nouă sală" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    submit: function(e) {
      e.preventDefault();

      var name = this.$refs.name.value;
      var details = this.$refs.descriere.value;
      var capacity = this.$refs.places.value;
      // var default_price = this.$refs.default_price.value;
      var default_price = 1;
      // var price = this.$refs.price.value;
      var price = 1;
      var valid_from = this.$refs.date_start.localValue;
      var valid_to = this.$refs.date_end.localValue;
      var img1 = this.$refs.img1.$refs.input.files[0];
      var img2 = this.$refs.img2.$refs.input.files[0];
      var img3 = this.$refs.img3.$refs.input.files[0];
      var img4 = this.$refs.img4.$refs.input.files[0];

      let room = new FormData();
      room.set("name", name);
      room.set("details", details);
      var amenitiesList = this.$refs.amenities.value;
      var amenitiesObj = amenitiesList.split(",");

      for (var i = 0; i < amenitiesObj.length; i++) {
        room.append("amenities[]", amenitiesObj[i]);
      }

      room.set("places", capacity);
      room.set("default_price", default_price);
      room.set("prices[1][price]", price);
      room.set("prices[1][valid_from]", valid_from);
      room.set("prices[1][valid_to]", valid_to);
      if (img1){
        room.append("images[]", img1);
      }
      if (img2){
        room.append("images[]", img2);
      }
      if (img3){
        room.append("images[]", img3);
      }
      if (img4){
        room.append("images[]", img4);
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_add_new_room"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(CREATE_ROOM, room)
          .then(() => {
            Swal.fire({
              title: "",
              text: "Succes! Camera a fost creată!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
          })
          .catch(() => {
            Swal.fire({
              title: "",
              text: "Ops! A apărut o eroare.",
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            });
          });
        //
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapGetters(["currentRoomInfo"])
  }
};
</script>
